//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import DateLib from "../../../../../inc/DateLib";
import ApiOrderBookings from "../../../../../app/api/authorized/admin/order/ApiOrderBookings";
import { route } from "../../../../../inc/Routes";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
import ButtonLinkShow from "../../../../components/button-link/__show";
import ButtonLinkAdd from "../../../../components/button-link/__add";
import InputPhone from "../../../../components/inputs/phone/phone";
import MultiSelect from "../../../../components/inputs/milti-select";
//Стили
import './styles.css';

export default function OrderBookingIndex({ ...other }) {
    return <Page {...other}>
        <OrderBookingIndexContent {...other} />
    </Page>
}

function OrderBookingIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_id, set_id] = useState('');
    const [_name, set_name] = useState('');
    const [_phone, set_phone] = useState('');
    const [_email, set_email] = useState('');
    const [_bookingCode, set_bookingCode] = useState('');
    const [_promocodeName, set_promocodeName] = useState('');
    const [_productIds, set_productIds] = useState([]);
    const [_price, set_price] = useState(null);

    const products = useSelector(state => state.adminOption.products);

    const handleProductsChange = (event) => {
        set_productIds(event.target.value);
    };

    useLayoutEffect(() => {
        contextPage.setTitle(`Полетное время`);
        contextContent.setTitle(`Полетное время`);
    }, [contextContent, contextPage]);

    const header = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            №
        </ListHeaderItem>,
        <ListHeaderItem
            key="name"
            name="name"
        >
            Пользователь
        </ListHeaderItem>,
        <ListHeaderItem
            key="phone"
            name="phone"
        >
            Телефон
        </ListHeaderItem>,
        <ListHeaderItem
            key="email"
            name="email"
        >
            Email
        </ListHeaderItem>,
        <ListHeaderItem
            key="bookingCode"
            name="bookingCode"
        >
            Код
        </ListHeaderItem>,
        <ListHeaderItem
           key="price"
           name="price"
        >
           Итого, руб
        </ListHeaderItem>,
        <ListHeaderItem
            key="product"
            name="product"
            isSort={false}
        >
            Продукт
        </ListHeaderItem>,
        <ListHeaderItem
            key="promocode"
            name="promocode"
        >
            Промокод
        </ListHeaderItem>,
        <ListHeaderItem
            key="status"
            name="status"
        >
            Статус
        </ListHeaderItem>,
        <ListHeaderItem
            key="created_at"
            name="created_at"
        >
            Создан
        </ListHeaderItem>,
        <ListHeaderItem
            key="updated_at"
            name="updated_at"
        >
            Изменен
        </ListHeaderItem>,
        <ListHeaderItem
            key="create"
            name="create"
            isSort={false}
        >
            {contextContent.checkPermission('create') ? <ButtonLinkAdd href={contextPage.routes.create.url()} /> : null}
        </ListHeaderItem>,
    ];

    const filters = [
        <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>id</InputNumber>,
        <InputText className="input_wf" value={_name} onChange={(e) => { set_name(e.target.value) }}>Пользователь</InputText>,
        <InputPhone className="input_wf" useReg={false} value={_phone} onChange={(e) => { set_phone(e.target.value) }}>Телефон</InputPhone>,
        <InputText className="input_wf" value={_email} onChange={(e) => { set_email(e.target.value) }}>Email</InputText>,
        <InputText className="input_wf" value={_bookingCode} onChange={(e) => { set_bookingCode(e.target.value) }}>Код</InputText>,
        <MultiSelect className="input_wf" options={products} value={_productIds} onChange={handleProductsChange}>Продукты</MultiSelect>,
        <InputText className="input_wf" value={_promocodeName} onChange={(e) => { set_promocodeName(e.target.value) }}>Промокод</InputText>,
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-bookings_column-width" key="id" data-label={header[0].props.children}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="name" data-label={header[1].props.children}><a href={route(`authorized.admin.user.users.show`,{id: row.booking_user_id})}>{row.name}</a></ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="phone" data-label={header[2].props.children}>{row.phone}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="email" data-label={header[3].props.children}>{row.email}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="bookingCode" data-label={header[4].props.children}>{row.bookingCode}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="price" data-label={header[5].props.children}>{row.total} ₽</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="product" data-label={header[6].props.children}>
                {row.products.map((product) => (
                <div key={product.id}>
                    <div>Время: {product.minutes}</div>
                    <div>Цена: {product.price} ₽ ({product.total} ₽)</div>
                </div>
                ))}
            </ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="promocode" data-label={header[7].props.children}>{row.promocode_name}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="status" data-label={header[8].props.children}>{row.status}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="created_at" data-label={header[9].props.children}>{DateLib.formatterDateTime(row.created_at)}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="updated_at" data-label={header[10].props.children}>{DateLib.formatterDateTime(row.updated_at)}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="show">
                {contextContent.checkPermission('show') ? <ButtonLinkShow href={contextPage.routes.show.url({ id: row.id })} /> : null}
            </ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiOrderBookings}
            renderRow={renderRow}
            searchParams={{ id: _id, name: _name, phone: _phone, email: _email, bookingCode: _bookingCode, price: _price, products: _productIds, promocode: _promocodeName }}
        />
    </>
}
