//реакт
import React, { useState, useLayoutEffect, useMemo, useRef } from 'react';
//Quill
import ReactQuill, { Quill } from 'react-quill';
//Стили
import './quillStyles.css';
import './contentStyles.css';
//Кастомные форматы Quill
import BoldBlot from './formats/bold';
import ColorClass from './formats/color';
import SizeClass from './formats/fontSize';
import AlignClass from './formats/align';
import IndentClass from './formats/indent';
import Link from './formats/link';

//компоненты
import Label from "../inputs/label";
import MessageError from "../message/__errors";


export default function ContentEditor({value = null, defaultContent = null, config = 'default', children, maxLength = 2000,  placeholder, errors, className = '', content = null, onChange, readOnly, ...other}) {
  const [editorContent, setEditorContent] = useState('');
  const quillRef = useRef(null);
  const [_warning, setWarning]= useState(null); 

  const presets = {
    defaultPreset: {
      toolbar: {
        container: [
          [{ 'header': [1, 2, 3, 4, 5, false] }],
          ['bold', 'italic', 'underline','strike'],
          [{'color': ColorClass.whitelist}],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          [{'customAlign': AlignClass.whitelist}],
          ['link'],
          ['clean'],
          [{ 'font': [] }, { 'size':  SizeClass.whitelist}],
          ['copyHTML'], ['pasteHTML']
        ],
        handlers: {
          'copyHTML': async () => {
            try{
              const html = quillRef.current.editor.root.innerHTML;
              await navigator.clipboard.writeText(html);
            }
            catch(err){
              console.error(err);
            }
          },
          'pasteHTML': async () => {
            try{
              const text = await navigator.clipboard.readText();
              quillRef.current.editor.clipboard.dangerouslyPasteHTML(text);
            }
            catch(err){
              console.error(err);
            }
          }
        }
      }
    },
  
    minimalPreset: {
        toolbar: {
          container: [
            [{ 'header': [1, 2, 3, 4, 5, false] }],
            ['bold', 'italic', 'underline','strike'],
            [{ 'font': [] }],
            ['clean'],
            ['copyHTML'], ['pasteHTML']
          ],
          handlers: {
            'copyHTML': async () => {
              try{
                const html = quillRef.current.editor.root.innerHTML;
                await navigator.clipboard.writeText(html);
              }
              catch(err){
                console.error(err);
              }
            },
            'pasteHTML': async () => {
              try{
                const text = await navigator.clipboard.readText();
                quillRef.current.editor.clipboard.dangerouslyPasteHTML(text);
              }
              catch(err){
                console.error(err);
              }
            }
          }
        }
    },
  };
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'color',
    'list', 'bullet', 'indent',
    'customAlign',
    'link',
    'font', 'size'
  ]

  const handleChange = (editorContent) => {
    setEditorContent(editorContent);
    if(editorContent != '<p><br></p>')
      onChange({ target: { value: editorContent } });
    else  
      onChange({ target: { value: null } });
    const length = editorContent.length ? editorContent.length : 0;
    if(length < maxLength){
      setWarning(null);
    }
    else setWarning('Превышен лимит контента!');
  }

  useLayoutEffect(() => {
    if(defaultContent) {
      setEditorContent(defaultContent);
    }
  }, [defaultContent]);

  function setPreset(config){
    switch(config){
      case('minimal'): return presets.minimalPreset;
    default: return presets.defaultPreset;
    }
  }

  const modules = useMemo(() => (setPreset(config)), []);

  return <>
      <Label>{children}</Label>
      <div className="editor-wrapper" > 
        <ReactQuill 
        ref={quillRef}
        readOnly={readOnly}
        value={editorContent} 
        onChange={handleChange}
        modules={modules}
        formats={formats}
        />
        <Label className="label__content-length">{editorContent.length ? editorContent.length
        + '/' + maxLength : '0/' + maxLength}</Label>
        <Label className="label__warning">{_warning}</Label>
      </div>
      {errors ? <MessageError errors={errors} sx={{width: "100%"}}/> : null}
  </>
}