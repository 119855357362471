import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
//Модули Content
import { ContextApp } from "../../modules/app/appContext";
//Компоненты
import MenuItem from "./__item";
import MenuItems from "./menu-items";
//Api
import ApiAuth from "../../../app/api/public/system/ApiAuth";
//Material UI
import { IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import CurrencyRubleOutlinedIcon from '@mui/icons-material/CurrencyRubleOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import ParaglidingOutlinedIcon from '@mui/icons-material/ParaglidingOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import SportsMotorsportsOutlinedIcon from '@mui/icons-material/SportsMotorsportsOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import AirplaneTicketOutlinedIcon from '@mui/icons-material/AirplaneTicketOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import PanoramaOutlinedIcon from '@mui/icons-material/PanoramaOutlined';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import ScubaDivingOutlinedIcon from '@mui/icons-material/ScubaDivingOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import DataSaverOnOutlinedIcon from '@mui/icons-material/DataSaverOnOutlined';
import MoreTimeOutlinedIcon from '@mui/icons-material/MoreTimeOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import BlurLinearOutlinedIcon from '@mui/icons-material/BlurLinearOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';

//Стили
import "./styles.css";
import styles from "./style";

import { route } from "../../../inc/Routes";

export default function Menu({ children, isOpen = false, openMenu, closeMenu, ...other }) {
    const { checkPermission } = useContext(ContextApp);
    const location = useLocation();

    const getMenuItems = (items) => {
        let availableItems = [];
        items.forEach((item) => {
            if (checkPermission(item.permission)) availableItems.push(item.elem);
        });
        return availableItems;
    };

    const handleMouseEnter = () => {
        if (!isOpen) openMenu();
    };

    const handleMouseLeave = () => {
        closeMenu();
    };

    return (
        <div
            {...other}
            className={`menu ${isOpen ? 'is-open' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={(e) => e.stopPropagation()} // предотвращает всплытие кликов до document
        >
            <div className={`menu__wrapper ${isOpen ? 'is-open' : ''}`}>
                <div className="menu__header">
                    <IconButton
                        onClick={isOpen ? closeMenu : openMenu}
                        sx={styles.iconMain}
                        size="large"
                    >
                        <MenuIcon fontSize="inherit" />
                    </IconButton>
                </div>
                <div className="menu__body">
                    <MenuItems
                        isOpen={isOpen}
                        closeMenu={closeMenu}
                        groupName="Заказы"
                        slug="Orders"
                        icon={<CurrencyRubleOutlinedIcon />}
                        menuItems={getMenuItems(
                            [
                                { permission: 'admin.order.certificates.index', elem: { label: 'Сертификаты', icon: ReceiptLongOutlinedIcon, path: route("authorized.admin.order.certificates.index") } },
                                { permission: 'admin.order.bookings.index', elem: { label: 'Полетное время  ', icon: ParaglidingOutlinedIcon, path: route("authorized.admin.order.bookings.index") } },
                                { permission: 'admin.order.balances.index', elem: { label: 'Депозиты', icon: UpdateOutlinedIcon, path: route("authorized.admin.order.balances.index") } },
                                { permission: 'admin.order.competitions.index', elem: { label: 'Соревнования', icon: SportsMotorsportsOutlinedIcon, path: route("authorized.admin.order.competitions.index") } },
                                { permission: 'admin.order.amo.index', elem: { label: 'AmoCrm', icon: BlurLinearOutlinedIcon, path: route("authorized.admin.order.amo.index") } },
                            ]
                        )}
                    />
                </div>
                <div className="menu__body">
                    <MenuItems
                        isOpen={isOpen}
                        closeMenu={closeMenu}
                        groupName="Контент"
                        slug="Content"
                        icon={<DashboardCustomizeOutlinedIcon />}
                        menuItems={getMenuItems(
                            [
                                { permission: 'admin.products.index', elem: { label: 'Продукты', icon: InterestsOutlinedIcon, path: route("authorized.admin.content.products.index") } },
                                { permission: 'admin.categories.index', elem:{ label: 'Категории', icon: CategoryOutlinedIcon, path: route("authorized.admin.content.categories.index") } },
                                { permission: 'admin.promocodes.index', elem: { label: 'Промокоды', icon: QrCode2OutlinedIcon, path: route("authorized.admin.content.promocodes.index") } },
                                { permission: 'admin.promotions.index', elem: { label: 'Акции', icon: DiscountOutlinedIcon, path: route("authorized.admin.content.promotions.index") } },
                                { permission: 'admin.news.index', elem: { label: 'Новости', icon: NewspaperOutlinedIcon, path: route("authorized.admin.content.news.index") } },
                            ]
                        )}
                    />
                </div>
                <div className="menu__body">
                    <MenuItems
                        isOpen={isOpen}
                        closeMenu={closeMenu}
                        groupName="Бронирование"
                        slug="Booking"
                        icon={<CollectionsBookmarkOutlinedIcon />}
                        menuItems={getMenuItems(
                            [
                                { permission: 'admin.booking.balance.index', elem: { label: 'Депозиты', icon: MoreTimeOutlinedIcon, path: route("authorized.admin.booking.balances.index") } },
                                { permission: 'admin.booking.balance-difference.index', elem: { label: 'Депозиты (разница)', icon: DifferenceOutlinedIcon, path: route("authorized.admin.booking.balance-diff.index") } },
                                { permission: 'admin.booking.times.index', elem: { label: 'Операции', icon: PublishedWithChangesOutlinedIcon, path: route("authorized.admin.booking.times.index") } },
                                { permission: 'admin.booking.bookings.index', elem: { label: 'Бронирования', icon: AirplaneTicketOutlinedIcon, path: route("authorized.admin.booking.bookings.index") } },
                            ]
                        )}
                    />
                </div>
                <div className="menu__body">
                    <MenuItems
                        isOpen={isOpen}
                        closeMenu={closeMenu}
                        groupName="Мероприятия"
                        slug="Events"
                        icon={<ScubaDivingOutlinedIcon />}
                        menuItems={getMenuItems(
                            [
                                { permission: 'admin.platforms.index', elem: { label: 'Площадки', icon: RoomOutlinedIcon, path: route("authorized.admin.event.platforms.index") } },
                                { permission: 'admin.banners.index', elem: { label: 'Банеры', icon: PanoramaOutlinedIcon, path: route("authorized.admin.event.banners.index") } },
                                { permission: 'admin.calendars.index', elem: { label: 'Календари бронирований', icon: EditCalendarOutlinedIcon, path: route("authorized.admin.event.calendar.calendars.index") } },
                                { permission: 'admin.calendars.index', elem: { label: 'Календари категорий', icon: DateRangeOutlinedIcon, path: route("authorized.admin.event.calendar.categories.index") } },
                            ]
                        )}
                    />
                </div>
                <div className="menu__body">
                    <MenuItems
                        isOpen={isOpen}
                        closeMenu={closeMenu}
                        groupName="Статистика"
                        slug="Statistics"
                        icon={<DataSaverOnOutlinedIcon />}
                        menuItems={getMenuItems(
                            [
                                { permission: 'admin.statistic.analytics.index', elem: { label: 'Аналитика', icon: QueryStatsOutlinedIcon, path: route("authorized.admin.statistic.analytics.index") } },
                                { permission: 'admin.statistic.parameters.index', elem: { label: 'Показатели', icon: BarChartOutlinedIcon, path: route("authorized.admin.statistic.parameters.index") } },
                            ]
                        )}
                    />
                </div>
                <div className="menu__body">
                    <MenuItems
                        isOpen={isOpen}
                        closeMenu={closeMenu}
                        groupName="Пользователи"
                        slug="users"
                        icon={<PeopleOutlinedIcon />}
                        menuItems={getMenuItems(
                            [
                                { permission: 'admin.users.index', elem: { label: 'Пользователи', icon: AssignmentIndOutlinedIcon, path: route("authorized.admin.user.users.index") } },
                                { permission: 'admin.roles.index', elem: { label: 'Роли', icon: BadgeOutlinedIcon, path: route("authorized.admin.user.roles.index") } },
                            ]
                        )}
                    />
                </div>
                <div className="menu__body">
                    <MenuItem
                        className="menu__item--outlined"
                        selectedClassName="menu__item--outlined-selected"
                        isOpen={isOpen}
                        closeMenu={closeMenu}
                        Icon={ShoppingCartOutlinedIcon}
                        path={route("authorized.account.home.index")}
                        active={location.pathname.startsWith(route("authorized.account.home.index"))}
                    >Магазин</MenuItem>
                </div>
                {checkPermission('account.order.certificates.index') &&
                    <div className="menu__body">
                        <MenuItem
                            className="menu__item--outlined"
                            selectedClassName="menu__item--outlined-selected"
                            isOpen={isOpen}
                            closeMenu={closeMenu}
                            Icon={ReceiptLongOutlinedIcon}
                            path={route("authorized.account.order.certificates.index")}
                            active={location.pathname.startsWith(route("authorized.account.order.certificates.index"))}
                        >Сертификаты</MenuItem>
                    </div>
                }
                {checkPermission('account.booking.bookings.index') &&
                    <div className="menu__body">
                        <MenuItem
                            className="menu__item--outlined"
                            selectedClassName="menu__item--outlined-selected"
                            isOpen={isOpen}
                            closeMenu={closeMenu}
                            Icon={AirplaneTicketOutlinedIcon}
                            path={route("authorized.account.booking.bookings.index")}
                            active={location.pathname.startsWith(route("authorized.account.booking.bookings.index"))}
                        >Бронирования</MenuItem>
                    </div>
                }
                {checkPermission('account.booking.balances.index') &&
                    <div className="menu__body">
                        <MenuItem
                            className="menu__item--outlined"
                            selectedClassName="menu__item--outlined-selected"
                            isOpen={isOpen}
                            closeMenu={closeMenu}
                            Icon={UpdateOutlinedIcon}
                            path={route("authorized.account.booking.balances.index")}
                            active={
                                location.pathname.startsWith(route("authorized.account.booking.balances.index")) ||
                                location.pathname.startsWith(route("authorized.account.booking.times.index"))
                            }
                        >Депозиты</MenuItem>
                    </div>
                }
                <div className="menu__footer">
                {checkPermission('common.profile.show') &&
                    <div className="menu__body">
                        <MenuItem
                            isOpen={isOpen}
                            closeMenu={closeMenu}
                            selectedClassName="menu__item--outlined-selected"
                            className="menu__item--outlined"
                            Icon={AccountCircleOutlinedIcon}
                            path={route("authorized.common.profile.show")}
                            active={location.pathname.startsWith(route("authorized.common.profile.show"))}
                        >Профиль</MenuItem>
                    </div>
                }
                <MenuItem
                    isOpen={isOpen}
                    closeMenu={closeMenu}
                    Icon={ExitToAppIcon}
                    onClick={async () => { await ApiAuth.Logout(); }}
                >Выход</MenuItem>
                </div>
            </div>
        </div>
    );
}
